<template>
  <v-container>
    <v-row justify="center" v-if="dishList && dishList.length > 0">
      <v-card width="100%">
        <v-card-title>
          {{ $t('Common.products') }}
          <v-spacer></v-spacer>
          <v-text-field
            v-model="tableSearch"
            append-icon="mdi-magnify"
            :label="$t('Common.products_search')"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="dishList"
          :loading="loading"
          :search="tableSearch"
          :items-per-page="5"
        >
          <template v-slot:item.photos="{ item }">
            <span v-if="item.photos && item.photos.length > 0">Yes</span>
            <span v-else>No</span>
          </template>

          <template v-slot:item.modifiers="{ item }">
            <span
              v-if="item.modifiers && Object.keys(item.modifiers).length > 0"
              >Yes</span
            >
            <span v-else>No</span>
          </template>

          <template v-slot:item.price="{ item }">{{
            item.price | currency
          }}</template>
          <template v-slot:item.edit="{ item }">
            <v-btn
              :to="'/profile/wizard/menus/products_sub/new_product/' + item.id"
              depressed
              text
            >
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
          </template>

          <template v-slot:item.delete="{ item }">
            <v-icon small @click="deleteItemDialog(item.id)">delete</v-icon>
          </template>
        </v-data-table>
      </v-card>
    </v-row>
    <!-- dish delete confirmation -->
    <v-dialog v-model="deleteDialog" width="400">
      <v-card>
        <v-card-title class="mx-2">
          <v-icon large color="grey">delete</v-icon>
          <h4 class="ml-1 mt-2 fs-16 font-weight-medium">Delete Product?</h4>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="deleteDialog = false">Cancel</v-btn>
          <v-btn color="red" dark @click="deleteItem()">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {
    ProductInformation: () => import('./ProductInformation'),
    ProductDetails: () => import('./ProductDetails')
  },
  data() {
    return {
      product: null,
      hstep: 1,
      menus: null,
      id: null,
      headers: [
        { text: 'Product Name', value: 'name' },
        { text: 'Photos', value: 'photos' },
        { text: 'Modifiers', value: 'modifiers' },
        { text: 'Price', value: 'price' },
        { text: 'Edit', value: 'edit', sortable: false },
        { text: 'Delete', value: 'delete', sortable: false }
      ],
      tableSearch: '',
      deleteDialog: false
    }
  },
  beforeMount() {
    if (!this.dishList) {
      this.$store.dispatch('loadDishList', { uid: this.profile.id })
    }
  },
  computed: {
    ...mapGetters({
      dishList: 'dishList',
      profile: 'profile'
    }),

    loading() {
      return this.$store.getters.loading
    }
  },
  methods: {
    editItem(id) {
      this.$router.go('/profile/wizard/products_sub/new_product' + id)
    },
    deleteItemDialog(id) {
      this.deleteId = id
      this.deleteDialog = true
    },
    deleteItem() {
      this.$store.dispatch('deleteDish', this.deleteId)
      this.deleteDialog = false
    }
  }
}
</script>
<style>
.v-stepper__header {
  box-shadow: none;
  justify-content: flex-start !important;
}
.v-stepper__content {
  padding: 16px 40px 16px 23px;
}
.v-stepper__wrapper {
  padding: 4px;
}
.shadow {
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 4px 8px rgba(0, 0, 0, 0.04);
}
</style>
