var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(_vm.dishList && _vm.dishList.length > 0)?_c('v-row',{attrs:{"justify":"center"}},[_c('v-card',{attrs:{"width":"100%"}},[_c('v-card-title',[_vm._v("\n        "+_vm._s(_vm.$t('Common.products'))+"\n        "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('Common.products_search'),"single-line":"","hide-details":""},model:{value:(_vm.tableSearch),callback:function ($$v) {_vm.tableSearch=$$v},expression:"tableSearch"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.dishList,"loading":_vm.loading,"search":_vm.tableSearch,"items-per-page":5},scopedSlots:_vm._u([{key:"item.photos",fn:function(ref){
var item = ref.item;
return [(item.photos && item.photos.length > 0)?_c('span',[_vm._v("Yes")]):_c('span',[_vm._v("No")])]}},{key:"item.modifiers",fn:function(ref){
var item = ref.item;
return [(item.modifiers && Object.keys(item.modifiers).length > 0)?_c('span',[_vm._v("Yes")]):_c('span',[_vm._v("No")])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("currency")(item.price)))]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"to":'/profile/wizard/menus/products_sub/new_product/' + item.id,"depressed":"","text":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItemDialog(item.id)}}},[_vm._v("delete")])]}}],null,false,1169774595)})],1)],1):_vm._e(),_c('v-dialog',{attrs:{"width":"400"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"mx-2"},[_c('v-icon',{attrs:{"large":"","color":"grey"}},[_vm._v("delete")]),_c('h4',{staticClass:"ml-1 mt-2 fs-16 font-weight-medium"},[_vm._v("Delete Product?")])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.deleteDialog = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"red","dark":""},on:{"click":function($event){return _vm.deleteItem()}}},[_vm._v("Ok")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }